<template>
  <div>
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text">المصاريف</h1>
      <v-row class="mt-5">
        <v-col md="4" cols="12">
          <div class="d-md-flex">
            <v-btn
              tile
              color="primary"
              class="ml-2 mb-4 mb-md-0 mb-sm-0"
              :block="isScreenXs"
              @click="addDialog.open = true"
            >
              اضافة صرف <v-icon right> fa-plus </v-icon>
            </v-btn>
            <!-- <v-btn tile color="primary" class="ml-2 mb-4 mb-md-0 mb-sm-0" @click="goToInvoicePage"> فتح الفاتورة
                <v-icon right> fa-plus </v-icon>
              </v-btn> -->
            <v-btn
              tile
              color="success"
              :loading="xlsxData.downloadLoading"
              :block="isScreenXs"
              @click="getAllDataAxios"
            >
              تحميل اكسل <v-icon right> fa-download </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2" cols="12">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tableModifier.start_date"
                dense
                label="من"
                outlined
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="tableModifier.start_date" @input="menuStartDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2" cols="12">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tableModifier.end_date"
                dense
                label="الى"
                outlined
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="tableModifier.end_date" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="4" cols="12">
          <div class="d-flex flex-row">
            <v-text-field v-model="table.search" label="البحث" outlined dense @keyup.enter="search()"></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white">fa-search</v-icon>
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="table.headers"
            loading-text="جاري التحميل ... الرجاء الانتظار"
            :items="table.Data"
            :options.sync="tableOptions"
            :server-items-length="table.totalData"
            :loading="table.loading"
            class="elevation-1"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template slot="item._id" slot-scope="props"> {{ table.totalData - props.index }} </template>
            <template v-slot:item.money="{ item }">
              {{ numberWithComma(item.money) }}
            </template>
            <template v-slot:item.account_division_current="{ item }">
              {{ item.account_division_current.class_name + ' - ' + item.account_division_current.leader }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="editItem(item)">
                    fa-edit
                  </v-icon>
                </template>
                <span>تعديل</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FF5252" v-bind="attrs" size="20" v-on="on" @click="deleteItem(item)">
                    fa-trash
                  </v-icon>
                </template>
                <span>حذف</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- add dialog -->
    <v-dialog v-model="addDialog.open" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDialog.isFormValidAdd">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="addData.expenses_name"
                    :rules="rules.expenses_name"
                    :loading="studentLoading"
                    :items="servicesData"
                    item-text="name"
                    item-value="_id"
                    clearable
                    outlined
                    dense
                    label="نوع الصرف"
                    @click:clear="addData.expenses_name = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="addData.money"
                    label="المبلغ"
                    :rules="rules.money"
                    outlined
                    required
                    @keypress="isNumber($event)"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    v-model="menuNextPayment"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="addData.date"
                        :rules="rules.date"
                        :disabled="nextPaymentDisable"
                        dense
                        label="التاريخ"
                        outlined
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="addData.date" @input="menuNextPayment = false"> </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined rows="4" row-height="20" v-model="addData.note" label="الملاحظة"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDialog.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="addDialog.loading"
            :disabled="!addDialog.isFormValidAdd"
            @click="addStudentBillsData"
          >
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add Class dialog -->
    <!-- edit Class dialog -->
    <v-dialog v-model="dialogEdit.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogEdit.isFormValid">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="editedItem.expenses_name"
                    :rules="rules.expenses_name"
                    :loading="studentLoading"
                    :items="servicesData"
                    item-text="name"
                    item-value="_id"
                    clearable
                    outlined
                    dense
                    label="نوع الصرف"
                    @click:clear="editedItem.expenses_name = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.money"
                    label="المبلغ"
                    :rules="rules.money"
                    outlined
                    required
                    @keypress="isNumber($event)"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    v-model="menuNextPaymentEdit"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.date"
                        :rules="rules.date"
                        :disabled="nextPaymentDisable"
                        dense
                        label="التاريخ"
                        outlined
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.date" @input="menuNextPaymentEdit = false"> </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined rows="4" row-height="20" v-model="editedItem.note" label="الملاحظة"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="dialogEdit.loading"
            :disabled="!dialogEdit.isFormValid"
            @click="editItemConform"
          >
            تعديل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا الحساب ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary white--text" :loading="deleteItemLoading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dailog -->
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/api/api'
import numberWithComma from '@/constant/number'
import router from '@/router'

export default {
  data () {
    return {
      // billsTypeSelected: null

      menuStartDate: false,

      menuEndDate: false,

      rules: {
        expenses_name: [value => !!value || 'نوع الصرف مطلوب'],
        money: [value => !!value || 'المبلغ مطلوبة'],
        date: [value => !!value || 'التاريخ مطلوب'],
      },

      tableModifier: {
        start_date: null,
        end_date: null,
      },

      menuNextPayment: false,

      menuNextPaymentEdit: false,

      addData: {
        expenses_name: null,
        money: null,
        date: null,
        note: null,
      },

      editedItem: {},

      dialogEdit: {
        open: false,
        isFormValid: false,
        loading: false,
      },

      deleteItemLoading: false,

      content_url: null,

      dialogDelete: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      xlsxData: {
        list: null,
        listLoading: true,
        downloadLoading: false,
        filename: 'المصروفات',
        autoWidth: true,
        bookType: 'xlsx',
      },

      isScreenXs: false,

      deletedItem: {},

      tableOptions: {},

      addDialog: {
        open: false,
        isFormValidAdd: false,
        saveLoading: false,
        loading: false,
      },

      billsTypeSelectItems: [
        { text: 'الكل', value: null },
        { text: 'المدفوعة', value: true },
        { text: 'غير المدفوعة', value: false },
      ],

      isDeletedBillSelectItems: [
        { text: 'غير محذوفة', value: false },
        { text: 'محذوفة', value: true },
      ],

      servicesData: [],

      serviceLoading: false,

      studentsData: [],

      studentLoading: false,

      nextPaymentDisable: false,

      table: {
        search: null,
        totalData: 0,
        Data: [],
        loading: true,
        showImageDailog: false,
        imageUrlForShow: null,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: '_id',
            width: 1,
          },
          {
            text: 'نوع الصرف',
            sortable: false,
            value: 'service_name',
          },
          { text: 'المبلغ', value: 'money' },
          { text: 'التاريخ', sortable: false, value: 'date' },
          { text: 'الملاحظة', sortable: false, value: 'note' },
          { text: 'العمليات', value: 'actions', sortable: false },
        ],
      },
    }
  },
  watch: {
    tableOptions: {
      handler () {
        this.getDataAxios()
      },
      deep: true,
    },
    '$route.query.search': {
      handler (search) {
        this.table.search = search
        this.getDataAxios(search)
      },
      deep: true,

      // immediate: true,
    },
    '$vuetify.breakpoint': {
      handler () {
        if (this.$vuetify.breakpoint.xs) {
          this.isScreenXs = true
        } else {
          this.isScreenXs = false
        }
      },
      deep: true,
    },
    'tableModifier.start_date': {
      handler () {
        this.getDataAxios()
      },
      // deep: true,
    },

    'tableModifier.end_date': {
      handler () {
        this.getDataAxios()
      },
      // deep: true,
    },
  },

  created () {
    this.getServicesExpenses()
  },
  methods: {
    async getDataAxios () {
      let { search } = this.table
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions
      if (!search) {
        search = ''
      }

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.getExpenses({
        study_year,
        start_date: this.tableModifier.start_date,
        end_date: this.tableModifier.end_date,
        page,
        limit: itemsPerPage,
        search,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.table.loading = false
        this.table.Data = response.data.results.data
        this.table.totalData = response.data.results.count
        this.content_url = response.data.content_url
      }
    },

    goToInvoicePage () {
      this.$router.push('invoice/4987')
    },

    showImage (image) {
      this.table.showImageDailog = true
      this.table.imageUrlForShow = image
    },

    search () {
      this.$router.replace(
        {
          query: { search: this.table.search },
        },
        () => {},
      )
    },

    async addStudentBillsData () {
      const study_year = JSON.parse(localStorage.getItem('study_year'))

      this.addDialog.loading = true

      const response = await Api.addExpenses({
        expenses_name: this.addData.expenses_name,
        money: this.addData.money,
        date: this.addData.date,
        study_year,
        note: this.addData.note,
      })

      if (response.status === 401) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction(response.data.results, 'primary')
        this.getDataAxios()
      }
    },

    deleteItem (item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      this.deleteItemLoading = true

      const response = await Api.removeExpenses(this.deletedItem._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.deleteItemLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.getDataAxios()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    async getServicesExpenses () {
      this.serviceLoading = true

      const response = await Api.getServiceExpenses()

      if (response.status === 401) {
        this.serviceLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.serviceLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.serviceLoading = false
        this.servicesData = response.data.results
      }
    },

    isNumber (evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    editItem (item) {
      this.editedItem = { ...item }
      this.dialogEdit.open = true
    },

    async editItemConform () {
      this.dialogEdit.open = true
      this.dialogEdit.loading = true

      const response = await Api.editExpenses({
        expenses_name: this.editedItem.expenses_name,
        money: this.editedItem.money,
        date: this.editedItem.date,
        note: this.editedItem.note,
        id: this.editedItem._id,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.getDataAxios()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    showDialogfunction (bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    async getAllDataAxios () {
      this.xlsxData.downloadLoading = true

      let { search } = this.table
      if (!search) {
        search = ''
      }

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.getExpenses({
        study_year,
        start_date: this.tableModifier.start_date,
        end_date: this.tableModifier.end_date,
        page: 1,
        limit: 1000000000000,
        search,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.xlsxData.downloadLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.allData = response.data.results.data
        this.handleDownload()
      }
    },

    goToAddPage () {},

    goToBillsPage (student_id, student_name) {
      router.push(`studentBills/showBills/student_id/${student_id}/student_name/${student_name}`)
    },

    handleDownload () {
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['نوع الصرف', 'المبلغ', 'التاريخ', 'الملاحظة']
        const filterVal = ['service_name', 'money', 'date', 'note']

        // const { list } = this
        const data = this.formatJson(filterVal, this.allData)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.xlsxData.filename,
          autoWidth: this.xlsxData.autoWidth,
          bookType: this.xlsxData.bookType,
        })
        this.xlsxData.downloadLoading = false
      })
    },

    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },

    numberWithComma,
  },
}
</script>
